@import '~bootstrap/scss/bootstrap';
@import './splash.scss';
@import './navbar.scss';
@import './howitworks.scss';
@import './tutorial.scss';
@import './footer.scss';

* {
  margin: 0;
  padding: 0;
}
