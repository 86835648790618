.navbar-docked .nav-link,
.navbar-docked .vr {
  color: white !important;
}

nav.navbar-scrolled {
  background-color: rgba(255, 255, 255, 0.8) !important;
  backdrop-filter: blur(5px);
}

nav {
  transition: background-color 100ms;
}

nav.navbar-docked .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.2);
}

nav.navbar-docked .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 0.7)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

nav .dropdown-menu a {
  white-space: nowrap;
}
